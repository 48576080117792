<template>
  <div>
    <validation-observer ref="simple">
      <b-form>
        <b-card-code title="  تعديل مكافأة للموظف">
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <b-row>
              <b-col
                md="6"
                xl="36"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="employe"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label> الموظف</label>
                    <v-select
                      v-model="employe"
                      :options="optionemploye"
                      :reduce="(val) => val.value"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                md="6"
                xl="6"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="date"
                    :state="errors.length > 0 ? false : null"
                  >
                    <label>تاريخ </label>
                    <flat-pickr
                      v-model="date"
                      class="form-control"
                      placeholder="حدد تاريخ "
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                md="6"
                xl="6"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="re"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label>  المبلغ المدفوع</label>
                    <b-form-input
                      id="input-default"
                      v-model="paid_amount"
                      type="number"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                md="6"
                xl="6"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="re"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label>  المبلغ المخصوم</label>
                    <b-form-input
                      id="input-default"
                      v-model="deduction"
                      type="number"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                md="6"
                xl="6"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="re"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label>   تكلفة التحويل</label>
                    <b-form-input
                      id="input-default"
                      v-model="deliver_fees"
                      type="number"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                md="6"
                xl="6"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="re"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label>  طريقة التحويل </label>
                    <b-form-input
                      id="input-default"
                      v-model="deliver_type"
                      type="text"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                md="6"
                xl="6"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="re"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label>  الزيادة  </label>
                    <b-form-input
                      id="input-default"
                      v-model="bonus"
                      type="number"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                md="1"
                xl="3"
              >
                <b-button
                  variant="purple"
                  @click="updatePayment"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  <span class="align-middle"> تعديل الدفعة</span>
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </b-card-code>
      </b-form>
    </validation-observer>
    <b-col
      md="4"
      xl="2"
    >
      <b-button
        variant="purple "
        @click="$router.go(-1)"
      >
        <span class="align-middle">رجوع</span>
      </b-button>
    </b-col>
  </div>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow,
  BCol,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BTab,

  BFormDatepicker,
  BFormCheckbox,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BButton,
  BForm,
  BFormInvalidFeedback,
  BTabs,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  name: '',

  components: {
    flatPickr,
    ToastificationContent,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BFormInvalidFeedback,
    VueGoodTable,
    BForm,
    ValidationObserver,
    ValidationProvider,
    BTabs,
    BTab,
    BCardCode,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    Cleave,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BRow,

    BCol,
  },

  data() {
    return {
      optionerewardType: [{
        label: 'عينية',
        value: 1,
      },

      {
        label: 'مادية',
        value: 0,
      },
      ],

      title: '',
      amount: '',
      rewardType: '',
      employe: '',
      date: '',
      searchTerm: '',
      paid_amount: '',
      deduction: '',
      deliver_fees: '',
      deliver_type: '',
      notes: '',
      optionemploye: [],

    }
  },

  created() {
    this.getUserPayment()
    this.getemploye()
  },

  methods: {

    getemploye() {
      this.optionemploye = []
      this.$http.get('/api/v1/get-all-users').then(res => {
        //console.log(res)
        const employe = res.data
        employe.forEach((el) => {
          this.optionemploye.push({ value: el.id, label: `${el.first_name} ${el.last_name} `
 }); });
        //console.log(this.optionemploye)
      })
    },
    getUserPayment() {
      this.options = []
      this.$http.get(`/api/v1/employee_payments/${this.$route.params.id}`).then(res => {
        //console.log(res.data.data)
        this.date = res.data.data.date
        this.employe = res.data.data.user_id
        this.paid_amount = res.data.data.paid_amount
        this.deduction = res.data.data.deduction
        this.deliver_type = res.data.data.deliver_type
        this.deliver_fees = res.data.data.deliver_fees
      })
    },

    updatePayment() {
      const data = {
        date: this.date,
        user_id: this.employe,
        paid_amount: this.paid_amount,
        deduction: this.deduction,
        bonus: this.bonus,
        deliver_fees: this.deliver_fees,
        deliver_type: this.deliver_type,
      }
      const url = `/api/v1/employee_payments/${this.$route.params.id}`
      this.$http.put(url, data).then(res => {
        //console.log(res)
        this.$swal({
          title: 'تم التعديل بنجاح',
          icon: 'success',
          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    },
    //
  },
}
</script>

<style scoped>
.form-control {
  font-weight: bold;
}
label {
  color: rgba(112, 192, 203, 255);
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #7e7a9f;
  margin-top: 25px;
}

.align-left {
  margin-left: 750px;
}
.but {
  top: 40px;
}
</style>
